// The initial state of the App container

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContainerState, User } from './types';
import { localStorageGet, localStorageSet } from '../../../utils/localStorage';

export const initialState: ContainerState = {
  user: localStorageGet('user') ? JSON.parse(localStorageGet('user') as string) : null,
  token: localStorageGet('token') ? JSON.parse(localStorageGet('token') as string) : null,
  loading: false,
  focus: true,
  sessionStartTime: localStorageGet('sessionStartTime') ?? null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    testConn(state, action: PayloadAction<object>) {},
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
      localStorageSet('user', JSON.stringify(action.payload));
    },
    getData() {},
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    changeFocus(state, action: PayloadAction<boolean>) {
      state.focus = action.payload;
    },
  },
});

export const { actions: appActions, reducer, name: sliceKey } = appSlice;
